<template>
  <VCol
    class="d-flex align-center"
    :cols="wrapperWidth"
  >
    <VCol
      v-if="title.length"
      class="d-flex align-center pt-0"
      :cols="titleWidth"
    >
      <span class="input-title">{{ title }}</span>
    </VCol>
    <VCol
      v-if="description.length"
      class="d-flex align-center py-1"
      :cols="getInnerWrapperWidth"
    >
      <VCol
        class="d-flex align-center px-0 py-0"
        :class="additionalInputWrapClasses"
        :cols="getInputWidth"
      >
        <VTextField
          v-model.lazy="textToEdit"
          v-mask="mask"
          :class="alignCenter ? 'text-center' : ''"
          color="success"
          :disabled="disabled"
          :height="height"
          :label="label"
          :placeholder="placeholder"
          :regular="regular"
          required
          :rules="noRules? [] : rules"
          :single-line="noSingleLine"
          :solo="!soloDisabled"
          @change="emitText('change')"
        />
      </VCol>
      <VCol
        v-if="description"
        :cols="descriptionWidth"
      >
        <span class="input-description">{{ description }}</span>
      </VCol>
    </VCol>
    <VCol
      v-else
      class="py-0"
      :class="additionalInputWrapClasses"
      :cols="getInputWidth"
    >
      <VTextField
        v-model.lazy="textToEdit"
        v-mask="mask"
        :class="alignCenter ? 'text-center' : ''"
        color="success"
        :disabled="disabled"
        :flat="flat"
        :height="height"
        :hide-details="hideDetails"
        :label="label"
        :outlined="!soloDisabled"
        :placeholder="placeholder"
        :regular="regular"
        required
        :rules="noRules ? [] : rules"
        :single-line="noSingleLine"
        :solo="!soloDisabled"
        @change="emitText('change')"
      />
    </VCol>
  </VCol>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: [String, Number],
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '41px',
    },
    number: {
      type: Boolean,
      default: false,
    },
    wrapperWidth: {
      type: [String, Number],
      default: '12',
    },
    titleWidth: {
      type: [String, Number],
      default: '3',
    },
    inputWidth: {
      type: [String, Number],
    },
    descriptionWidth: {
      type: [String, Number],
    },
    soloDisabled: {
      type: Boolean,
      default: false,
    },
    regular: {
      type: Boolean,
      default: false,
    },
    noSingleLine: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: undefined,
    },
    maxLength: {
      type: [String, Number],
    },
    additionalInputWrapClasses: {
      type: String,
      default: '',
    },
    noRules: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textToEdit: this.text,
      rules: [
        (value) => (!!value || value === 0) || 'Пустое поле',
        (value) => {
          if (this.maxLength) {
            return value.length <= this.maxLength;
          }
          return true;
        },
      ],
    };
  },
  computed: {
    getInnerWrapperWidth() {
      return this.title.length ? 8 : 12;
    },
    getInputWidth() {
      const width = this.inputWidth ? this.inputWidth : 12;
      return this.description.length > 0 ? 4 : width;
    },
  },
  methods: {
    filterInput(text) {
      if (this.number) {
        if (text.match(/\D/)) {
          this.textToEdit = text.replace(/\D/g, '');
        }
        if (text === '' || text === '0') {
          this.textToEdit = '0';
        } else if (text[0] === '0' && text.length > 0) {
          this.textToEdit = text.substring(1);
        }
      }
      this.emitText();
    },
    emitText(emitionType = 'update') {
      if (this.textToEdit && this.textToEdit.length >= 0) {
        const text = this.maxLength
          ? this.textToEdit.trim().slice(0, this.maxLength)
          : this.textToEdit;
        this.$emit(emitionType, text);
      } else {
        this.textToEdit = this.text;
      }
    },
  },
  watch: {
    text(newText) {
      this.textToEdit = newText;
    },
    textToEdit(newValue) {
      this.filterInput(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

::v-deep {
  .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
  }
  .text-center {
    input {
      text-align: center;
    }
  }
  .v-text-field__details {
    min-height: 0 !important;
  }
  .v-messages{
    opacity: 0;
    position: absolute;
    min-height: 0 !important;
  }
  .error--text {
    opacity: 1;
  }
}
</style>
